@import '~@circlon/angular-tree-component/css/angular-tree-component.css';

////Barra de rolagem
///* Works on Firefox */
//* {
//  scrollbar-width: thin;
//  scrollbar-color: blue orange;
//}
//
///* Works on Chrome, Edge, and Safari */
//*::-webkit-scrollbar {
//  width: 12px;
//}
//
//*::-webkit-scrollbar-track {
//  background: orange;
//}
//
//*::-webkit-scrollbar-thumb {
//  background-color: blue;
//  border-radius: 20px;
//  border: 3px solid orange;
//}
//FIm barra de rolagem


body, html {
  overflow: hidden;
  height: 100%;

  app-full-layout {
    display: flex;
    flex-direction: column;
    height: 100%;

    app-navbar {
      flex: inherit;
      flex-shrink: 0;
    }

    .main-container-wrapper {
      flex: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;

      .main-panel {
        display: flex;
        height: 100%;
        width: 100%;
        overflow: hidden;

        .main-content {
          display: flex;
          height: 100%;
          width: 100%;
          overflow: hidden;

          .content-wrapper {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            padding: 0 10px;
          }
        }


      }
    }
  }

  //.main-container-wrapper {
  //  height: calc(100vh - 3.98rem);
  //  overflow-y: auto;
  //}
  //.main-content{
  //  height: 1vh;
  //  .content-wrapper{
  //    height: 100%;
  //  }
  //}
}

/* Border Color */

/* Border Color */
//.ng-select.ng-invalid.ng-touched .ng-select-container{
//  border-color: red;
//}


///* Placeholder Color */
//.ng-select.ng-invalid.ng-touched .ng-select-container .ng-placeholder{
//  color: red;
//}

.ngx-datatable i {
  font-size: 18px;
}

img.avatar {
  object-fit: cover;
}

/* You can add global styles to this file, and also import other style files */
.box-grid-header {
  margin: 0;

  .col-1 {
    text-align: center;
  }

  .add {
    text-align: center;
    background: #f7f7f8;
    margin: 0 auto;
    padding: 5px 0;
    border-radius: 40px;

    i {
      height: 30px;
      width: 30px;
      cursor: pointer;
    }
  }

}

.box-grid {
  margin: 0;
  background: #f7f7f8;
  border-radius: 10px;
  padding: 8px 10px;

  .no-records-founds {
    text-align: center;
    font-size: 13px;
    font-weight: bold;

  }

  .box-container > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }

  .box-container {
    &:not(:last-child) {
      border-bottom: 1px solid #e8e6e6;
    }

    margin-bottom: 5px;

    .remove {
      //padding-top: 35px;
      position: absolute;
      right: 0;
      padding: 0;
      top: 40%;

      i {
        width: 100%;
        text-align: center;
        height: 37px;
        //width: 37px;
        cursor: pointer;
      }
    }
  }
}


.ng-select {
  &.ng-select-disabled {
    .ng-select-container {
      background-color: #EDEDED !important;
    }
  }
}

.alert-warning {
  color: #664d03 !important;
  background-color: #fff3cd !important;
  border-color: #ffecb5 !important;
}
